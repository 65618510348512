import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/*',
    name: 'home',
    component: home,
    meta: {
      title: 'DesignerKit'
    },
  }
]




const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push // 为解决router大于3.0版本的问题，而设立的解决代码
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router
