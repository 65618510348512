<template>
<div>
  <!-- 顶部边栏 -->
  <div class="topbar">
    <div class="logo" onclick=""><img src="../assets/img/logo-withtext.png" /></div>
    <div class="pic" onclick=""><img src="../assets/img/pic.png" /></div>

  </div>
  <!-- 主要模块 -->
  <div class="mainpart">
    <div class="main-content" style="width: 100%;">
      <!-- 搜索引擎 -->
      <div class="rowSearch">
        <img src="//www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png"/>
        <form action="https://www.baidu.com/s">
          <input type="text" name="wd" class="inputSearch" /><input type="submit" class="inputBtn btn btn-green" value="百度搜索" />
        </form>
      </div>

      <!-- 分类标签 -->
      <div class="rowTab">
        <div class="tab-content">
          <div class="btn" v-bind:class="{'btn-green': this.current_range===0,'btn-gray': this.current_range!==0}" id="tab_0" @click="changeRange(0)">显示所有</div>
          <div
              v-for="type in typeData"
              class="btn"
              v-bind:class="{'btn-green': current_range=== type.type_num ,'btn-gray': current_range!== type.type_num }"
              :id="getTab(type.type_num)"
              @click="changeRange(type.type_num)"
          >
            {{ type.type_name }}</div>
        </div>
      </div>

      <!-- 内容模块 -->
      <div class="content-box" id="content">

        <span v-for="item in webData" class="block" v-bind:id="item.cate_id" @click="open_url(item.link)" v-show="current_range === 0 || current_range === item.cate_id"  >
          <div class="img">
            <img v-if="item.if_logo === '有'" :src="get_pic_url(item.logo)" >
            <img v-else src="../assets/img/logo.png">
          </div>
          <div class="title">
            <h1>{{ item.name }}</h1>
            <h2>{{ item.intro_s }}</h2>
          </div>
          <div class="descrip">
            <div>{{ item.intro_long }}</div>
          </div>
        </span>

        <div class="warning" onclick="window.open('http://www.designerkit.cn/')">内容显示不出来点我</div>

      </div>
    </div>
  </div>
  <div class="mainpart-foot" style="padding: 0px;">
    <div class="foot-bar">
      <div class="foot-info">
        <p>DesignerKit Eason的个人工具箱</p>
        <p>EasonSunFZ@outlook.com</p>
      </div>
      <div class="copyright">Copyright © 2012-2019 EASONSUN All Rights Reserved.</div>
      <a href="https://beian.miit.gov.cn/?spm=a2c4g.11186623.J_9220772140.116.39ca7150lODt6b#/Integrated/index">
        <div class="copyright">浙ICP备2021008567号-1</div>
      </a>
    </div>
  </div>

</div>
</template>
<script>

import axios from "axios";

export default {
  data() {
    return {
      webData:"",
      typeData:"",
      current_range: 0,
    };
  },
  mounted: function () {
    this.getWebData();
    this.getTypeData();
  },
  methods: {
    // API测试模板
    getWebData() {
      let _this = this;
      let raw_data = [];
      let sort_data = [];
      let axios = require('axios');
      let config = {
        method: 'post',
        url: 'http://116.62.16.153:6003/get_web',
        headers: {
          'web_api_key': 'PwGoWznFta89De3JLx6pwR6M',
          'Access-Control-Allow-Origin': true,
        },
      };
      axios(config)
          .then(function (response) {
            raw_data = JSON.parse(JSON.stringify(response.data));
            sort_data = raw_data.data;
            _this.webData = sort_data.sort(_this.compare('star'));
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getTypeData() {
      let _this = this;
      let raw_data = [];
      let sort_data = [];
      let axios = require('axios');
      let config = {
        method: 'post',
        url: 'http://116.62.16.153:6003/get_type',
        headers: {
          'web_api_key': 'PwGoWznFta89De3JLx6pwR6M',
          'Access-Control-Allow-Origin': true,
        },
      };
      axios(config)
          .then(function (response) {
            raw_data = JSON.parse(JSON.stringify(response.data));
            sort_data = raw_data.data;
            _this.typeData = sort_data.sort(_this.compare2('type_num'));
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    changeRange(obj) {
      this.current_range = obj;
    },
    compare(obj) {
      return function (obj1, obj2) {
        var val1 = Number(obj1[obj]);
        var val2 = Number(obj2[obj]);
        if (val2 < val1) { return -1 }
        else if (val2 > val1) { return 1 }
        else { return 0 }
      }
    },
    compare2(obj) {
      return function (obj1, obj2) {
        var val1 = Number(obj1[obj]);
        var val2 = Number(obj2[obj]);
        if (val2 < val1) { return 1 }
        else if (val2 > val1) { return -1 }
        else { return 0 }
      }
    },
    open_url(obj){
      window.open(obj);
    },
    get_pic_url(obj){
      let pic_url_1 = obj[0];
      let pic_url_2 = JSON.stringify(pic_url_1['url']);
      return pic_url_2.replace(/"/g,"")
    },
    getTab(obj){
      return "tab_" + obj;
    }
  }
};
</script>
<style>

</style>
