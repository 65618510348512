import Vue from 'vue'
import App from './App';
import axios from 'axios';
import router from './router';
import './assets/less/main.less'; // 引入全局LESS样式
import './assets/less/passport-jquery-weui.less'; // 引入全局LESS样式
import './assets/less/passport-weui.less'; // 引入全局LESS样式
import './assets/less/perfect-scrollbar.less'; // 引入全局LESS样式
import './assets/less/swiper.min.less'; // 引入全局LESS样式


Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
// axios.defaults.BaseURL = '/api'


new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')